import utils from "./util";

const StarsDisplay = props => {
	return (
		<>
			{utils.range(1, props.stars).map(starId =>
				<div key={starId} className="star" />
			)}
		</>
	);
}

export default StarsDisplay