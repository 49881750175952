import {colors} from "./util";

const NumberButton = props => {

	return (
		<button
			className="number"
			onClick={() => props.clickButton(props.buttonNumber, props.status)}
			style={{backgroundColor: colors[props.status]}}
		>
			{props.buttonNumber}
		</button>
	)
}

export default NumberButton