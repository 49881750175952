import React, {useState} from "react";
import utils from "./util";

const UseGameState = () => {

	const [stars, setStars] = useState(utils.random(1, 9));
	const [availableNums, setAvailableNums] = useState(utils.range(1, 9));
	const [candidateNums, setCandidateNums] = useState([]);
	const [secondsLeft, setSecondsLeft] = useState(10);

	React.useEffect(() => {
		if (availableNums.length !== 0 && secondsLeft > 0) {
			const timeId = setTimeout(() => {
				setSecondsLeft(secondsLeft - 1);
			}, 1000);
			return () => {
				clearTimeout(timeId);
			}
		}
	})

	const setGameState = (newCandidateNums) => {
		if (utils.sum(newCandidateNums) === stars) {
			// remove candidate numbers from available numbers
			const newAvailableNums = availableNums.filter(value => !newCandidateNums.includes(value));
			setAvailableNums(newAvailableNums);
			// reset candidate numbers
			setCandidateNums([]);
			// set a new stars number from the new available numbers
			setStars(utils.randomSumIn(newAvailableNums, 9))
		} else {
			setCandidateNums(newCandidateNums);
		}
	}
	return {stars, availableNums, candidateNums, secondsLeft, setGameState}
}

export default UseGameState