import utils from "./util";
import {useState} from "react";
import StarsDisplay from "./StarsDisplay";
import NumberButton from "./numberButton";
import PlayAgain from "./PlayAgain";
import React from "react";
import UseGameState from "./UseGameState";

const Game = (props) => {

	const {
		stars,
		availableNums,
		candidateNums,
		secondsLeft,
		setGameState,
	} = UseGameState();

	const gameStatus = availableNums.length === 0 ? 'won' : secondsLeft === 0 ? 'lost' : 'active';

	const candidatesAreWrong = utils.sum(candidateNums) > stars;

	const onNumberClick = (buttonNumber, currentStatus) => {
		if (gameStatus !== 'active' || currentStatus === 'used') {
			return;
		}
		let newCandidateNums;
		if (candidateNums.includes(buttonNumber)) {
			// remove from candidates
			newCandidateNums = candidateNums.filter(value => {
				return value !== buttonNumber
			});
		} else {
			// add to candidates
			newCandidateNums = [...candidateNums, buttonNumber];
		}
		setGameState(newCandidateNums);
	}

	const numberStatus = (buttonNumber) => {
		if (!availableNums.includes(buttonNumber)) {
			return 'used';
		}
		if (candidateNums.includes(buttonNumber)) {
			return candidatesAreWrong ? 'wrong' : 'candidate';
		}
		return 'available';
	}

	return (
		<div className="game">
			<div className="help">
				Pick 1 or more numbers that sum to the number of stars
			</div>
			<div className="body">
				<div className="left">
					{gameStatus !== 'active' ? (
						<PlayAgain onClick={props.reset} gameStatus={gameStatus} />
					) : (
					<StarsDisplay stars={stars} />
						)}
				</div>
				<div className="right">
					{utils.range(1, 9).map(buttonNumber =>
						<NumberButton
							key={buttonNumber}
							status={numberStatus(buttonNumber)}
							clickButton={onNumberClick}
							buttonNumber={buttonNumber}
						/>
					)}
				</div>
			</div>
			<div className="timer">Time Remaining: {secondsLeft}</div>
		</div>
	);
};

const StarMatch = () => {
	const [gameId, setGameId] = useState(1);
	const resetGame = () => {
		setGameId(gameId + 1);
	}

	return <Game key={gameId} reset={resetGame}/>
}

export default StarMatch
